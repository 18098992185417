// put your js here :-)

let color = {
    '': '#FFD426',
    about: '#FF473D',
    services: '#FF473D',
    work: '#4AD5D5',
    news: '#FFD426',
    contacts: '#11DA87'
};

function active() {
    let url = document.location.pathname.split('/')[1];
    document.querySelectorAll('header .main-nav nav a.another-page').forEach(e => {
        console.log(e.pathname);
        if (e.pathname.split('/')[1] === url) {
            e.style.borderBottom = `solid 40px ${color[e.pathname.split('/')[1]]}`;
        } else if (e.pathname.split('/')[1] === "services" && (url === "audit" || url === "packing" || url === "neuro" || url === "ttl" || url === "positioning" || url === "inner-audit" || url === "automation")) {
            e.style.borderBottom = `solid 40px ${color[e.pathname.split('/')[1]]}`;
        }
    });
    document.querySelectorAll('#show-menu nav a.another-page').forEach(e => {
        console.log(e.pathname);
        if (e.pathname.split('/')[1] === url) {
            e.style.borderLeft = `solid 65px ${color[e.pathname.split('/')[1]]}`;
        } else if (e.pathname.split('/')[1] === "services" && (url === "audit" || url === "packing" || url === "neuro" || url === "ttl" || url === "positioning" || url === "inner-audit" || url === "automation")) {
            e.style.borderLeft = `solid 65px ${color[e.pathname.split('/')[1]]}`;
        }
    });
    document.querySelectorAll('footer .main-nav a.another-page').forEach(e => {
        console.log(e.pathname);
        if (e.pathname.split('/')[1] === url) {
            e.style.color = `${color[e.pathname.split('/')[1]]}`;
        } else if (e.pathname.split('/')[1] === "services" && (url === "audit" || url === "packing" || url === "neuro" || url === "ttl" || url === "positioning" || url === "inner-audit" || url === "automation")) {
            e.style.color = `${color[e.pathname.split('/')[1]]}`;
        }
    });
}

active();

function openNav(e) {
    document.getElementById("show-menu").style.opacity = "1";
    document.getElementById("show-menu").style.left = "0";
}

function closeNav() {
    document.getElementById("show-menu").style.opacity = "0";
    document.getElementById("show-menu").style.left = "-120vw";
}

if (localStorage.getItem('cookieSeen') != 'shown') {
    $('.cookies').delay(500).fadeIn("300");
    localStorage.setItem('cookieSeen','shown')
}
;
$('.cookie-button').click(function () {
    $('.cookies').fadeOut("300");
})

function checkbox() {
    if (document.getElementById('confidential').checked) {
        console.log(123)
        document.getElementById('btn-form').disabled = false;
    } else {
        document.getElementById('btn-form').disabled = true;
    }
}

$('#news1').click(function () {
    $('.news-left').show();
    $('.news-right').hide();
    $('#news1').addClass("active");
    $('#news2').removeClass("active");
})
$('#news2').click(function () {
    $('.news-right').show();
    $('.news-left').hide();
    $('#news2').addClass("active");
    $('#news1').removeClass("active");
})


